<template>
  <div v-if="content" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="content">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('content') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="content.name" type="text" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('description')"
            label-for="description"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <ckeditor
              :editor="editor"
              v-model="content.description"
              :config="editorConfig"
              @ready="onEditorReady"
            ></ckeditor>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Folien hochladen</strong>
          </legend>
          <AttachmentGallery
            :attachments="content.contentAttachments"
            v-on:attachment-list:delete="onDeleteContentAttachment"
            :sortByName="false"
            :showDeleteButton="isAdmin"
          ></AttachmentGallery>

          <DropzoneUpload
            :complete="isAttachmentUploadComplete"
            :parentId="content.id"
            parentType="contentAttachments"
            :uploadCallback="uploadContentAttachment"
            v-on:dropzone-upload:reset="isAttachmentUploadComplete = false"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showPreviousButton="buttons.showPreviousButton"
            :showNextButton="buttons.showNextButton"
            :showDeleteButton="buttons.showDeleteButton"
            :model="content"
            modelEditRoute="ContentEditPage"
            modelListRoute="Contents"
            modelRouteParamName="contentNumber"
            :nextModelNumber="content.nextContentNumber"
            :previousModelNumber="content.previousContentNumber"
            :updateCallback="updateContent"
            :createCallback="createContent"
            :deleteCallback="deleteContent"
            :fetchCallback="fetchContents"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import ButtonBar from '@/components/ButtonBar.vue';
import DropzoneUpload from '@/components/DropzoneUpload';
import AttachmentGallery from '@/components/AttachmentGallery';

export default {
  name: 'ContentEditPage',
  components: {
    Widget,
    Breadcrumbs,
    ckeditor: CKEditor.component,
    ButtonBar,
    DropzoneUpload,
    AttachmentGallery,
  },
  props: {
    contentNumber: {
      type: [Number, String],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: parseInt(this.contentNumber, 10) || null,
      buttonsDisabled: false,
      editor: InlineEditor,
      editorConfig: {},
      isAttachmentUploadComplete: false,
      uploadContentId: null,
      contentAttachments: [],
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters(['getContent', 'getContentByNumber']),
    buttons() {
      return {
        showPreviousButton: this.content && this.content.previousContentNumber ? true : false,
        showNextButton: this.content && this.content.nextContentNumber ? true : false,
        showDeleteButton: this.isAdmin && this.content && this.content.number !== '_new',
      };
    },
    content() {
      return this.getContentByNumber(this.number);
    },
    isNew() {
      return this.content && this.content._id === '_new';
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('content'), route: { name: 'Contents' } },
        { name: this.content && this.content.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initContent',
      'fetchContents',
      'fetchContentByNumber',
      'updateContent',
      'createContent',
      'deleteContent',
      'uploadContentAttachment',
      'deleteContentAttachment',
    ]),

    async onDeleteContentAttachment(event) {
      try {
        await this.deleteContentAttachment({
          parentId: this.content.id,
          attachmentId: event.attachmentId,
          attachmentType: event.attachmentType,
        });
      } catch (error) {
        console.error('Error deleting content attachment:', error);
      }
    },

    onEditorReady(event) {},
    async setup() {
      if (this.number !== null) {
        await this.fetchContentByNumber(this.number);
      } else {
        this.initContent();
        this.number = '_new';
      }
      this.contentAttachments = this.content.contentAttachments || [];
    },
  },
  async mounted() {
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadContentAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
    await this.setup();
    this.contentAttachments = this.content.contentAttachments;
  },
  async beforeRouteUpdate(to, from, next) {
    const newNumber = parseInt(to.params.contentNumber, 10);
    if (!isNaN(newNumber) && newNumber !== this.number) {
      this.number = newNumber;
      await this.setup();
    }
    next();
  },
};
</script>

<style scoped lang="scss"></style>
